import React from "react";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { TypePhantom, TypeToledo } from "@at/design-system-foundation";

export const SkeletonAdvertTitle = () => {
  return (
    <>
      <TypePhantom as="h1" weight="500">
        <Skeleton
          style={{
            maxWidth: "200px",
            height: "100%",
            lineHeight: "unset",
            marginBottom: "10px",
          }}
        />
      </TypePhantom>
      <TypeToledo as="p" weight="400">
        <Skeleton
          style={{
            maxWidth: "400px",
            height: "100%",
            lineHeight: "unset",
          }}
        />
      </TypeToledo>
    </>
  );
};
