import React from "react";

import Skeleton from "react-loading-skeleton";

export const SkeletonCVP = () => {
  return (
    <Skeleton
      style={{
        height: "148px",
        marginTop: "16px",
      }}
    />
  );
};
