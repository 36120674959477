import React from "react";

import Skeleton from "react-loading-skeleton";

import { PillContainer } from "../../components/heading/advert-pills.styles";

export const SkeletonDescriptionPills = () => {
  return (
    <PillContainer
      style={{
        display: "flex",
        flexWrap: "wrap",
        marginTop: "24px",
      }}
    >
      {[0, 1, 2, 3].map((index) => {
        return (
          <Skeleton
            key={index}
            style={{
              width: "100px",
              margin: "0 10px 10px 0",
              lineHeight: "unset",
            }}
          />
        );
      })}
    </PillContainer>
  );
};
