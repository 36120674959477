import React from "react";

import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

import { useMediaQuery } from "react-responsive";

import { AdvertImageSection } from "../../components/advert/advert-image/product-page-gallery.styled";
import {
  StyledGalleryGridImage,
  StyledGalleryGridContainer,
} from "../../components/gallery/gallery-grid/gallery-grid.styled";
import { StyledGallerySliderContainer } from "../../components/gallery/gallery-slider/gallery-slider.styled";
import { Gallery } from "../../components/gallery/gallery.styled";

export const SkeletonGallery = () => {
  const isNarrowScreen = useMediaQuery({
    query: "(max-width: 768px)",
  });

  return (
    <AdvertImageSection>
      <Gallery>
        {isNarrowScreen ? (
          <StyledGallerySliderContainer>
            <Skeleton
              style={{
                display: "block",
                aspectRatio: "4/3",
              }}
            />
          </StyledGallerySliderContainer>
        ) : (
          <StyledGalleryGridContainer>
            {[0, 1, 2, 3].map((index) => {
              return (
                <StyledGalleryGridImage
                  key={index}
                  style={{ overflow: "hidden", aspectRatio: "4/3" }}
                >
                  <Skeleton style={{ display: "block", height: "100%" }} />
                </StyledGalleryGridImage>
              );
            })}
          </StyledGalleryGridContainer>
        )}
      </Gallery>
    </AdvertImageSection>
  );
};
