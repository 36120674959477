import React from "react";
import Skeleton from "react-loading-skeleton";

import { AdvertPriceStyled } from "../../components/heading/advert-heading.styles";
import { AdminFeeWording } from "../../components/heading/price-breakdown/price-breakdown.styles";

export const SkeletonPrice = () => {
  return (
    <>
      <AdvertPriceStyled withSaving>
        <Skeleton
          style={{
            maxWidth: "150px",
          }}
        />
      </AdvertPriceStyled>
      <AdminFeeWording
        style={{
          display: "block",
        }}
      >
        <Skeleton
          style={{
            maxWidth: "250px",
          }}
        />
        <Skeleton
          style={{
            maxWidth: "350px",
          }}
        />
      </AdminFeeWording>
    </>
  );
};
