import styled from "styled-components";

import { NavStyled } from "../../components/navigation/advert-navigation.styles";

export const SkeletonNavigationBar = styled(NavStyled)`
  > * > span {
    display: flex;
    align-content: center;
  }
`;
