import React from "react";

import { useAppContext } from "@at/sauron-platform-core/lib/app/app-context.hoc";

import { SkeletonAdvertTitle } from "./skeleton-advert-title";
import { SkeletonCVP } from "./skeleton-cvp";
import { SkeletonDescriptionPills } from "./skeleton-description-pills";
import { SkeletonGallery } from "./skeleton-gallery";
import { SkeletonNavigationBar } from "./skeleton-navigation-bar";
import { SkeletonPrice } from "./skeleton-price";
import { Container as Sidebar } from "../../components/advert/buying-panel/buying-panel-component.styles";

import * as Styles from "../product-page.styles";

const SkeletonProductPage = () => {
  const {
    isDesktop,
    config: { desktopView },
  } = useAppContext();

  return (
    <>
      <Styles.GlobalProductPageStyles />
      <div
        id={
          `layout-` +
          ((desktopView as boolean) || isDesktop() ? "desktop" : "mobile")
        }
        role="alert"
        data-testid="skeleton-layout"
      >
        <Styles.AdvertContainerStyles breakout>
          <SkeletonNavigationBar />
          <Styles.AdvertArticleContainer>
            <SkeletonGallery />
            <Sidebar>
              <SkeletonAdvertTitle />
              <SkeletonDescriptionPills />
              <SkeletonPrice />
              <SkeletonCVP />
            </Sidebar>
          </Styles.AdvertArticleContainer>
        </Styles.AdvertContainerStyles>
      </div>
    </>
  );
};

export default SkeletonProductPage;
