import React from "react";

import Skeleton from "react-loading-skeleton";

import * as Styled from "./skeleton-navigation-bar.styles";
import { Save } from "../../components/heading/advert-heading.styles";
import {
  BackToSearchLink,
  Share,
} from "../../components/navigation/advert-navigation.styles";

export const SkeletonNavigationBar = () => {
  return (
    <Styled.SkeletonNavigationBar>
      <BackToSearchLink>
        <Skeleton
          style={{
            width: "150px",
            height: "32px",
          }}
        />
      </BackToSearchLink>
      <Share>
        <Skeleton
          style={{
            width: "32px",
            height: "32px",
          }}
        />
      </Share>
      <Save>
        <Skeleton
          style={{
            width: "32px",
            height: "32px",
          }}
        />
      </Save>
    </Styled.SkeletonNavigationBar>
  );
};
